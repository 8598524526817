.serviceImg {
  @apply w-full;
  filter: hue-rotate(0deg) brightness(1.05);
  position: relative;
  z-index: 0;
}

.buyCircle,
.rentCircle,
.sellCircle {
  z-index: 1;
  @apply absolute text-gray-400 bg-white w-20 h-20 lg:w-28 lg:h-28 border-4 border-black rounded-full shadow-xl flex flex-col items-center justify-center p-4 text-xs;
}

.buyCircle {
  @apply animate-drop duration-300 lg:w-24 lg:h-24 right-24 lg:right-72 border-black outline-4 top-8 lg:top-20;
}

.rentCircle {
  @apply animate-drop-1sd top-48 lg:top-52 border-black w-16 h-16 lg:w-20 right-0 lg:right-20 lg:h-20;
}

.sellCircle {
  @apply animate-drop-2sd top-4 lg:top-24 right-0 lg:right-32;
}
