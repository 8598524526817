:root {
  --font-mono: 'Figtree', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI',
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --primary-color: #f7873d;
  --secondary-color: #ffc444;
  --accent-color: #ff758f;
  --secondary-base-color: #595959;
  --primary-text-color: #262626;
  --secondary-text-color: #808080;
  --accent-text-color: #ff758f;
  --border-color: #00b96b;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --light-bg-color: #efefef;
  --dark-bg-color: #2a3439;
}

body {
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

.single-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
