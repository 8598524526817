.layout-in-home {
  padding-top: 98px;

  @screen sm {
    padding-top: 130px;
  }
}

.layout {
  padding-top: 66px;

  @screen sm {
    padding-top: 74px;
  }
}

.awssld__container {
  height: auto !important;

  @screen sm {
    height: auto !important;
  }
}

.awssld__container {
  padding-bottom: calc(100vh - 98px) !important;

  @screen sm {
    padding-bottom: calc(100vh - 130px) !important;
  }
}

.slick-dots {
  position: absolute;
  left: 0;
  bottom: -35px;
  padding-left: 20px;
  text-align: left;
  & li {
    margin-right: 20px;
    & div {
      @apply bg-gray-300/50;
    }
    &.slick-active > div {
      @apply bg-accent;
    }
  }
}

.slick-dots li {
  @apply inline-block;
}
.slick-dots span {
  @apply bg-gray-500;
}
.slick-dots .slick-active span {
  @apply bg-accent rounded-l-full rounded-r-full w-12;
}

& .slick-prev {
  position: absolute !important;
  right: 60px !important;
  bottom: 0px !important;
  transform: translateY(100%);
  @apply bg-white;
}

& .slick-next {
  position: absolute !important;
  right: 0 !important;
  @apply bg-white;
}

.carot-shift:hover {
  & > svg {
    transform: translateX(5px);
  }
}
.awssld__content {
  background-color: transparent;
  position: relative;
  height: 100%;
}

.awssld__container {
  height: 100vh;
  @screen sm {
    height: auto;
  }
}

.awssld__bullets {
  display: none;
}
.aws-sld {
  &__content {
    & p {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition: transform 0.45s cubic-bezier(0.15, 0.3, 0.15, 1),
        opacity 0.35s ease-out;
    }
    & p:nth-child(2) {
      transition-delay: 0.05s, 0.05s;
    }
    &--exit {
      & p {
        transition: transform 0.225s cubic-bezier(0.85, 0, 0.85, 0.7),
          opacity 0.4s ease-out;
      }
      & p:nth-child(2) {
        transition-delay: 0.05s, 0.05s;
      }
    }
    &--moveLeft {
      & p {
        transform: translate3d(-50px, 0, 0);
        opacity: 0;
      }
    }
    &--moveRight {
      & p {
        transform: translate3d(50px, 0, 0);
        opacity: 0;
      }
    }
  }
}

.slider-item {
  position: relative;
  /* height: 100%; */
}

.slick-con {
  position: relative;
  & .slick-list {
    marginleft: '-30px';
    marginbottom: '24px';
  }

  & .slick-next,
  & .slick-prev {
    bottom: 0;
    transform: none !important;
  }
}

.portfolio-section {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='100' viewBox='0 0 1600 100'%3E%3Cpath fill='%23FFF' d='M800 100 0 0 1600 0Z'/%3E%3C/svg%3E"),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='100' viewBox='0 0 1600 100'%3E%3Cpath fill='%23FFF' d='M800 0 0 100 1600 100Z'/%3E%3C/svg%3E"),
    radial-gradient(black, black, black);
  background-repeat: no-repeat;
  background-size: 100% 100px, 100% 100px, 100%;
  background-position: top center, bottom center, 50%;
  padding: 200px 30px 150px 30px;

  @screen sm {
    padding: 224px 30px 180px 30px;
  }
}

.section-wrap {
  grid-gap: 50px;
  display: grid !important;
  max-width: 1400px;
  margin: 0 auto;
}

.testimonial-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  padding: 0 30px 30px 30px;
  text-align: center;
  align-items: center;
  @apply shadow-xl rounded-lg bg-white relative;
}

.testimonial-box img {
  margin: -64px auto 20px auto;
  width: 128px;
  height: 128px;
  padding: 4px;
  @apply bg-white shadow-md relative border-2 border-white rounded-full;
}

.backx {
  position: absolute;
  margin: -64px auto 20px auto;
  width: 138px;
  height: 138px;
  background: red;
}

.topper {
  position: relative;
  margin-top: 0px !important;
}

.contact {
}

.testimonial-page {
  & .testimonial-box img {
    margin: -20px auto 20px auto;
    width: 100% !important;
    height: 100% !important;
    border-radius: none !important;
  }
}

body > h1,
body > div,
body > span {
  /* filter: grayscale(1) !important; */
  & .bg-gray-500 {
    background: black !important;
  }
  & .text-gray-500 {
    color: black !important;
  }

  & .bg-accent {
    background: black;
  }

  & img {
    /* filter: grayscale(0) !important; */
  }
}

body > img {
  /* filter: grayscale(0) !important; */
}

body > h1 > span {
  color: black !important;
}
