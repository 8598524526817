.content {
  @apply min-h-screen;
}

/* header */
.headerWrapper {
  @apply fixed bg-black top-0 w-full z-30 duration-300 transition-all;
}

/* footer */
.footerWrapper {
  @apply bg-black text-white pt-44 pb-24;
}
